import React, {useContext, useState, useCallback, Suspense} from "react";
import CoinsNavbar from "./CoinsNavbar";
import ReactPlayer from "react-player";
import "./StartPage.css";
import Modal from "react-bootstrap/Modal";
import {Button, Row, Col, Spinner, ProgressBar} from "react-bootstrap";
import {Input} from "semantic-ui-react";
import Camera from "./Camera";
import CryptoJS from "crypto-js";
import {EmotionContext} from "./EmotionProvider";
import Footer from "./Footer";
import {Link} from 'react-router-dom';

import RadarChart from 'react-svg-radar-chart';
import 'react-svg-radar-chart/build/css/index.css';

import { LazyLoadImage } from 'react-lazy-load-image-component';



const StartPage = () => {
    const [show, setShow] = useState(true);
    const [email, setEmail] = useState("");
    const [data, setData] = useState([]);
    const {emotions, setEmotions} = useContext(EmotionContext);
    const crypto_key = process.env.REACT_APP_CRYPTO_KEY;
    const [check, setCheck] = useState(false);
    const [check2, setCheck2] = useState(false);
    const [end, setEnd] = useState(false);
    const [secondModal, setSecondModal] = useState(false);
    const [showRandom, setShowRandom] = useState(false);
    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [emotionData, setEmotionData] = useState([]);
    const [progress, setProgress] = useState(0);
    const [displaySec, setDisplaySec] = useState(true);

    const [meanE, setMeanE] = useState({
        "emotion_mean": {
            "averages": {
                "angry": 0.00,
                "disgusted": 0.00,
                "fearful": 0.00,
                "happy": 0.00,
                "neutral": 0.00,
                "sad": 0.00,
                "surprised": 0.00
            }
        }
    })
    const [showEmotions, setShowEmotions] = useState(true)


    const handleWatch = (state) => {
        setData([...data, [state["playedSeconds"], emotions]]);
        setProgress(state["playedSeconds"]);
        setMinutes(Math.floor(((state["playedSeconds"])) / 60));
        setSeconds(((state["playedSeconds"])) % 60)
        setEmotions([]);
        setEmotionData([...emotionData, emotions]);

        if (seconds < 8) {
            setDisplaySec(true)
        } else {
            setDisplaySec(false)
        }
    };

    const handleEnd = (state) => {
        setEnd(true);
        fetchEmotionData();
        setSecondModal(true)
    };

    const [postId, setPostId] = useState(null)

    const fetchEmotionData = useCallback(async () => {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({_id: email, emotions: data})
        };
        const result = await fetch('https://coinproject82.compel.ch/facerecognition/users', requestOptions);
        setMeanE(await result.json())
        setShowEmotions(false)
    }, [email, data]);


    return (
        <>
            <CoinsNavbar/>


            <LazyLoadImage
                className="background"
                // use normal <img> attributes as props
          />



            <Modal show={show} size="xl">
                <Modal.Dialog size="lg">
                    <Modal.Header>
                        <Modal.Title>Research project of collaborative network (MIT, UoC, HSLU)
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>Please provide your Happimeter email address.</p>
                        <Row>
                            <Col xs={6} md={4}>
                                <div className="email">
                                    <Input
                                        placeholder="Happimeter Email"
                                        name="email"
                                        onChange={(e) => setEmail(CryptoJS.AES.encrypt(
                                            JSON.stringify(e.target.value),
                                            crypto_key
                                        ).toString())}
                                    />

                                </div>
                                <img className="img" src="./Instructions.jpeg"/>
                            </Col>

                            <Col xs={12} md={8}>
                                <p className="font_position"> If you have not completed the happimeter surveys yet,
                                    please proceed and:
                                    <ul>
                                        <li> Register <a
                                            href="https://www.happimeter.org/subscribe">here</a>.
                                        </li>
                                        <li> Complete the 4 surveys: NEO-FFI Survey, DOSPERT Survery, Schwartz Value
                                            Survery, Moral Survery.
                                        </li>
                                        <li> When you are logged in, you can find these surverys in the menu in the
                                            upper left corner at the Happimeter site.
                                        </li>
                                    </ul>
                                </p>


                            </Col>
                        </Row>

                        <div className="data">
                            <div style={{marginBottom: "-15px"}}>
                                <input
                                    type="checkbox"
                                    name="example"
                                    onClick={() => {
                                        setCheck2(!check2);
                                    }}
                                />
                                <label className="chechbox">
                                    {"I have completed the surveys and used the same email address for the registration in Happimeter."}


                                </label>
                            </div>
                        </div>
                        <div className="data">

                            <input
                                type="checkbox"
                                name="example"
                                onClick={() => {
                                    setCheck(!check);
                                }}
                            />


                            <label className="chechbox">
                                {"I accept the collection and storage of my emotion data for research purposes."}


                            </label>

                            <p> Click <Link to="/privacypolicy"> here </Link> for more details! </p>

                            <div>
                                <li>The video lasts 9:22 minutes, please keep the time free as the video cannot be
                                    paused.
                                </li>
                                <li>Please try not to look away.</li>
                                <li>Please do not cover your face and keep it unobstructed during the whole time.</li>
                                <li>Please confirm the camera permission for this Website or activate the camera
                                    manually.
                                </li>
                                <li>If you have rejected the camera function, please reload the page.</li>

                            </div>

                            <div style={{marginTop: "20px"}}>
                                <span style={{color: "#ff0000", fontSize: "20px"}}> &#9888; </span>
                                <span style={{fontSize: "18px"}}><font color="#ff0000">WARNING - This video contains graphical scenes that may be disturbing to some users.</font></span>
                            </div>
                        </div>

                    </Modal.Body>

                    <Button
                        onClick={() => {
                            check && check2 && email
                                ? setShow(false)
                                : setShow(true);
                        }}
                    >
                        Start Video
                    </Button>
                </Modal.Dialog>
                {/*<marquee direction="center">A Coin Project</marquee>*/}
                <p className="font_position=">
                    <center>Version: 0.5.7-001</center>
                </p>
            </Modal>

            <Modal show={secondModal}>
                <Modal.Dialog size="xl">
                    <Modal.Header>
                        <Modal.Title>Research project of collaborative network (MIT, UoC, HSLU)</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{fontWeight: 'bold'}}>Thanks for your emotions!</p>
                        {showEmotions
                            ? <div><p>The average emotions are loaded, this can take up to 10 seconds.</p>
                                <table style={{width: "100%"}}>
                                    <tr>
                                        <td> &#128512; &nbsp; Happy: <Spinner animation="border" size='sm'/> %</td>
                                        <td> &#128558; &nbsp; Suprised: <Spinner animation="border" size='sm'/> %</td>
                                    </tr>
                                    <tr>
                                        <td> &#128552; &nbsp; Fear: <Spinner animation="border" size='sm'/> %</td>
                                        <td> &#128534; &nbsp; Disgusted: <Spinner animation="border" size='sm'/> %</td>
                                    </tr>
                                    <tr>
                                        <td> &#128557; &nbsp; Sad: <Spinner animation="border" size='sm'/> %</td>
                                        <td> &#128545; &nbsp; Angry: <Spinner animation="border" size='sm'/> %</td>
                                    </tr>
                                    <tr>
                                        <td> &#128528; &nbsp; Neutral: <Spinner animation="border" size='sm'/> %</td>
                                    </tr>
                                </table>

                                <div>
                                    <h3>Radar Chart</h3>

                                    <div className="inline">


                                        Average Emotions
                                        <div className="div1">

                                        </div>
                                        <span style={{paddingLeft: "50px"}}>
                                        Your Emotions
                                        </span>
                                        <div className="div2">

                                        </div>

                                    </div>

                                    <RadarChart
                                        captions={{
                                            // columns
                                            //neutral: 'Neutral',
                                            happy: 'Happy',
                                            sad: 'Sad',
                                            surprised: 'Suprised',
                                            fear: 'Fear',
                                            disgusted: 'Disgusted',
                                            angry: 'Angry'
                                        }}
                                        data={[
                                            // Average Data
                                            {
                                                data: {
                                                    neutral: 0.77,
                                                    happy: 0.18 + 0.78 / 6,
                                                    sad: 0.10 + 0.78 / 6,
                                                    surprised: 0.01 + 0.78 / 6,
                                                    fear: 0.01 + 0.78 / 6,
                                                    disgusted: 0.005 + 0.78 / 6,
                                                    angry: 0.01 + 0.78 / 6
                                                },
                                                meta: {color: 'blue'}

                                            },
                                        ]}
                                        options={{
                                            scales: 3,
                                            zoomDistance: 0.97,
                                            captionProps: () => ({
                                                fontSize: 14,
                                                textAnchor: 'middle'
                                            })
                                        }}

                                        size={450}


                                    />
                                </div>


                            </div>
                            :
                            <div><p>You showed the following emotions:</p>
                                <table style={{width: "100%"}}>
                                    <tr>

                                        <td> &#128512; &nbsp; Happy: {Number(meanE.emotion_mean.averages.happy * 100).toFixed(2)} %</td>
                                        <td> &#128558; &nbsp; Suprised: {Number(meanE.emotion_mean.averages.surprised * 100).toFixed(2)} %</td>
                                    </tr>
                                    <tr>
                                        <td> &#128552; &nbsp; Fear: {Number(meanE.emotion_mean.averages.fearful * 100).toFixed(2)} %</td>
                                        <td> &#128534; &nbsp; Disgusted: {Number(meanE.emotion_mean.averages.disgusted * 100).toFixed(2)} %</td>
                                    </tr>
                                    <tr>
                                        <td> &#128557; &nbsp; Sad: {Number(meanE.emotion_mean.averages.sad * 100).toFixed(2)} %</td>
                                        <td> &#128545; &nbsp; Angry: {Number((meanE.emotion_mean.averages.angry) * 100).toFixed(2)} %</td>
                                    </tr>
                                    <tr>
                                        <td> &#128528; &nbsp; Neutral: {Number(meanE.emotion_mean.averages.neutral * 100).toFixed(2)} %</td>
                                    </tr>
                                </table>
                                <div>
                                    <h3>Radar Chart</h3>

                                    <div className="inline">


                                        Average Emotions
                                        <div className="div1">

                                        </div>
                                        <span style={{paddingLeft: "50px"}}>
                                        Your Emotions
                                        </span>
                                        <div className="div2">

                                        </div>

                                    </div>
                                    <RadarChart
                                        captions={{
                                            // columns
                                            //neutral: 'Neutral',
                                            happy: 'Happy',
                                            sad: 'Sad',
                                            surprised: 'Suprised',
                                            fear: 'Fear',
                                            disgusted: 'Disgusted',
                                            angry: 'Angry'
                                        }}
                                        data={[
                                            // Average Data
                                            {
                                                data: {
                                                    neutral: 0.77,
                                                    happy: 0.18 + 0.78 / 6,
                                                    sad: 0.10 + 0.78 / 6,
                                                    surprised: 0.01 + 0.78 / 6,
                                                    fear: 0.01 + 0.78 / 6,
                                                    disgusted: 0.005 + 0.78 / 6,
                                                    angry: 0.01 + 0.78 / 6
                                                },
                                                meta: {color: 'blue'}

                                            },
                                            {
                                                data: {
                                                    neutral: meanE.emotion_mean.averages.neutral,
                                                    happy: meanE.emotion_mean.averages.happy + meanE.emotion_mean.averages.neutral / 6,
                                                    surprised: meanE.emotion_mean.averages.surprised + meanE.emotion_mean.averages.neutral / 6,
                                                    fear: meanE.emotion_mean.averages.fearful + meanE.emotion_mean.averages.neutral / 6,
                                                    disgusted: meanE.emotion_mean.averages.disgusted + meanE.emotion_mean.averages.neutral / 6,
                                                    sad: meanE.emotion_mean.averages.sad + meanE.emotion_mean.averages.neutral / 6,
                                                    angry: meanE.emotion_mean.averages.angry + meanE.emotion_mean.averages.neutral / 6,
                                                },
                                                meta: {color: 'red', zoomDistance: 200}
                                            },
                                        ]}
                                        options={{
                                            scales: 3,
                                            zoomDistance: 0.97,
                                            captionProps: () => ({
                                                fontSize: 14,
                                                textAnchor: 'middle'
                                            })
                                        }}

                                        size={450}


                                    />
                                </div>


                            </div>


                        }


                    </Modal.Body>
                </Modal.Dialog>

            </Modal>

            <div className="camera">
                <div className="cameraBox">
                    <span style={{fontSize: "12px"}}>If you do not see your <b>webcam</b> here,
                        please reload page and allow to use your camera.</span>
                    {end ?
                        <>
                        </>
                        :
                        <Camera/>
                    }
                </div>
            </div>


            <div style={{pointerEvents: "none"}}>
                <div className="video">
                    <div className="videoBox">
                        <ReactPlayer
                            playing={!show}
                            url="https://vimeo.com/495184503"
                            config={{youtube: {playerVars: {disablekb: 1}}}}
                            onProgress={handleWatch}
                            onEnded={handleEnd}
                            width='864px'
                            height='486px'
                        />
                    </div>
                </div>
            </div>

            <div className='minPos'>
                <div style={{paddingTop: "10px"}}>
                    <ProgressBar className="shadow" now={progress / 562 * 100} label={`${progress.toFixed(0)}s`}
                                 srOnly style={{height: "20px"}}/>
                </div>


                {displaySec ?
                    <p> 0{minutes.toFixed(0)}:0{seconds.toFixed(0)} / 09:22</p>
                    :
                    <p>
                        0{minutes.toFixed(0)}:{seconds.toFixed(0)} / 09:22
                    </p>
                }

            </div>

            <Footer/>

        </>
    );
}

export default StartPage;

//https://vimeo.com/495184503 Neues Video
//https://vimeo.com/493298854 Altes Video
//https://www.youtube.com/watch?v=Uz2qkjLNZHc Sekunden Video